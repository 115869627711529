import {
  type AppShellDataProps,
  AppShellDataProvider,
} from '@trr/app-shell-data'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { createTheme, ThemeProvider } from '@mui/material'
import { Admin, Consumer } from 'features'
import { Content } from 'types'
import { Provider } from 'react-redux'
import { store } from 'Store'

const Index = ({
  appShellData,
}: {
  appShellData: AppShellDataProps<Content>
}) => {
  const theme = createTheme(themeOptions, defaultLocale)
  return (
    <ThemeProvider theme={theme}>
      <AppShellDataProvider value={appShellData}>
        <Provider store={store}>
          <Admin />
          <Consumer />
        </Provider>
      </AppShellDataProvider>
    </ThemeProvider>
  )
}

export default Index
