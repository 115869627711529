import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAppConfig, getIdToken } from '@trr/app-shell-data'
import { GetArbetsgivarInfoLinksResponse } from 'types'

const {
  COMMON: { PUBLIC_WEB_API_URL },
} = getAppConfig()

const extendedFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: PUBLIC_WEB_API_URL,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken() ?? ''}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

export const api = createApi({
  reducerPath: 'api',
  baseQuery: extendedFetchBaseQuery(),
  endpoints: (builder) => ({
    getArbetsgivarInfoLinks: builder.query<
      GetArbetsgivarInfoLinksResponse,
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      void
    >({
      query: () => `${PUBLIC_WEB_API_URL}/arbetsgivarinfo`,
    }),
  }),
})

export const { useGetArbetsgivarInfoLinksQuery } = api

export default api
