import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { api } from 'Api'

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
})

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([api.middleware]),
    preloadedState,
  })

const store = setupStore()

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>

export default store
