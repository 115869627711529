import { Link, Paper, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'

import { Content } from 'types'

const NoneFound = () => {
  const content = useEpiContent<Content>()

  return (
    <Paper
      data-testid="arbetsgivareInfoEmptyState"
      elevation={0}
      sx={{
        mt: 2,
        fontSize: 14,
        py: 3,
        textAlign: 'center',
        backgroundColor: 'surface.blue',
      }}
    >
      <Typography variant="subtitle2" component={'p'} mb={1}>
        {content.adminEmptyText}
      </Typography>
      <Link href={content.adminEmptyLinkUrl}>{content.adminEmptyLinkText}</Link>
    </Paper>
  )
}

export default NoneFound
